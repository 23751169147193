import React from 'react';
import './landingpage.css';
import logo from '../assets/Sensor.png';
import backgroundImg from '../assets/background.png'; // Import background image

const LandingPage = ({ onSignUp, onLearnMore }) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  return (
    <>
      {/* Fixed background div */}
      <div style={backgroundStyle}></div>
      
      {/* Main content container */}
      <div className="landing-page">
        <header className="landing-header">
          <img src={logo} className="landing-logo" alt="logo" />
          <h1>The Grady Seed Moisture Sensor </h1>
          <p>Measuring seed moisture content in seconds </p>
          <div className="cta-container">
            <button className="cta-button primary" onClick={onSignUp}>
              BUY NOW!!
            </button>
            <button className="cta-button secondary" onClick={onLearnMore}>
              Learn More
            </button>
          </div>
        </header>
      </div>
    </>
  );
};

export default LandingPage;
