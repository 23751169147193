// src/components/dashboard/dashboard.jsx
import React, { useState, useEffect } from 'react';
import { getUserData, getUserByEmail, getOrderDetails } from '../../components/services/firebase.service';
import './dashboard.css';
import backgroundImg from '../assets/login_bacground.png'; // Import background image

const Dashboard = ({ onBackHome }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  
  // Fixed background style
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Get user ID and email from localStorage
        const userId = localStorage.getItem('userId');
        const userEmail = localStorage.getItem('userEmail');
        
        if (!userId && !userEmail) {
          throw new Error('You are not logged in. Please sign in to view your dashboard.');
        }
        
        let user;
        
        // Try to get user data by ID first
        if (userId) {
          user = await getUserData(userId);
        }
        
        // If no user found by ID, try by email
        if (!user && userEmail) {
          user = await getUserByEmail(userEmail);
          
          // If user found by email, update localStorage with correct ID
          if (user && user.uid) {
            localStorage.setItem('userId', user.uid);
          }
        }
        
        if (!user) {
          throw new Error('User profile not found. Please try logging in again.');
        }
        
        // Prepare user profile data
        const profile = {
          displayName: user.displayName || userEmail,
          email: userEmail,
          address: user.address || {}
        };
    
        // Process orders - they're stored as object keys in Realtime DB
        let orders = [];
        if (user.orders) {
          // Fetch complete order details for each order reference
          const orderPromises = Object.entries(user.orders).map(async ([orderId, orderRef]) => {
            try {
              // Fetch the full order details from orders/{userId}/{orderId}
              const orderDetails = await getOrderDetails(userId, orderId);
              return {
                id: orderId,
                ...orderRef,
                ...orderDetails // Merge with order details
              };
            } catch (error) {
              console.error(`Error fetching order ${orderId}:`, error);
              return {
                id: orderId,
                ...orderRef,
                error: 'Failed to load complete details'
              };
            }
          });
          
          orders = await Promise.all(orderPromises);
        }
        
        // Process devices - might be stored differently in your DB
        let devices = [];
        if (user.devices) {
          devices = Object.entries(user.devices).map(([deviceId, device]) => ({
            id: deviceId,
            ...device
          }));
        }
        
        // Process licenses
        let licenses = [];
        if (user.licenses) {
          licenses = Object.entries(user.licenses).map(([licenseId, license]) => ({
            id: licenseId,
            ...license
          }));
        }
        
        setUserData({
          profile,
          orders,
          devices,
          licenses
        });
      } catch (err) {
        setError(err.message);
        console.error('Error fetching user data:', err);
      } finally {
        setLoading(false);
      }
    };
      
      fetchUserData();
    }, []);
  
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      return 'Invalid Date';
    }
  };
  
  if (loading) {
    return (
      <>
        {/* Fixed background div */}
        <div style={backgroundStyle}></div>
        
        <div className="dashboard-page">
          <div className="dashboard-container">
            <div className="dashboard-loading">
              <div className="spinner"></div>
              <p>Loading your dashboard...</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  if (error) {
    return (
      <>
        {/* Fixed background div */}
        <div style={backgroundStyle}></div>
        
        <div className="dashboard-page">
          <div className="dashboard-container">
            <div className="dashboard-error">
              <h2>Unable to load dashboard</h2>
              <p>{error}</p>
              <div className="error-actions">
                <button onClick={() => window.location.reload()}>Try Again</button>
                <button onClick={onBackHome}>Back to Home</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  return (
    <>
      {/* Fixed background div */}
      <div style={backgroundStyle}></div>
      
      <div className="dashboard-page">
        <div className="dashboard-container">
          <div className="dashboard-header">
            <h1>Your Dashboard</h1>
            <div className="user-welcome">
              Welcome, {userData.profile.displayName || userData.profile.email}
            </div>
            <button className="back-button" onClick={onBackHome}>Back to Home</button>
          </div>
          
          <div className="dashboard-tabs">
            <button 
              className={activeTab === 'profile' ? 'active' : ''}
              onClick={() => setActiveTab('profile')}
            >
              Profile
            </button>
            {/* <button 
              className={activeTab === 'devices' ? 'active' : ''}
              onClick={() => setActiveTab('devices')}
            >
              Devices
            </button>
            <button 
              className={activeTab === 'licenses' ? 'active' : ''}
              onClick={() => setActiveTab('licenses')}
            >
              Licenses
            </button> */}
            <button 
              className={activeTab === 'purchases' ? 'active' : ''}
              onClick={() => setActiveTab('purchases')}
            >
              Purchase History
            </button>
          </div>
          
          <div className="dashboard-content">
            {activeTab === 'profile' && <ProfileTab userData={userData} />}
            {/* {activeTab === 'devices' && <DevicesTab userData={userData} formatDate={formatDate} />}
            {activeTab === 'licenses' && <LicensesTab userData={userData} formatDate={formatDate} />} */}
            {activeTab === 'purchases' && <PurchasesTab userData={userData} formatDate={formatDate} />}
          </div>
        </div>
      </div>
    </>
  );
};

// Profile Tab Component
const ProfileTab = ({ userData }) => {
  const { profile } = userData;
  
  return (
    <div className="dashboard-page">
    <div className="profile-tab">
      <div className="profile-card">
        <h2>Your Profile</h2>
        <div className="profile-details">
          <div className="profile-field">
            <label>Name</label>
            <div>{profile.displayName || 'Not set'}</div>
          </div>
          <div className="profile-field">
            <label>Email</label>
            <div>{profile.email}</div>
          </div>
          
          <h3>Shipping Address</h3>
          {profile.address && (profile.address.line1 || profile.address.city) ? (
            <div className="address-info">
              <p>{profile.address.line1}</p>
              {profile.address.line2 && <p>{profile.address.line2}</p>}
              <p>
                {[
                  profile.address.city, 
                  profile.address.state, 
                  profile.address.postalCode
                ].filter(Boolean).join(', ')}
              </p>
              <p>{profile.address.country}</p>
            </div>
          ) : (
            <p className="no-data-message">No address information available</p>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

// Devices Tab Component
const DevicesTab = ({ userData, formatDate }) => {
  const { devices = [] } = userData;
  
  if (devices.length === 0) {
    return <div className="no-data-message">You don't have any devices yet</div>;
  }
  
  return (
    <div className="devices-tab">
      <h2>Your Devices</h2>
      <div className="devices-grid">
        {devices.map(device => (
          <div className="device-card" key={device.id}>
            <div className="device-header">
              <h3>{device.name || 'Grady Sensor'}</h3>
              <span className={`device-status ${device.license?.active ? 'active' : 'inactive'}`}>
                {device.license?.active ? 'Active' : 'Inactive'}
              </span>
            </div>
            <div className="device-details">
              <div className="detail-item">
                <span>Serial Number</span>
                <strong>{device.serialNumber || device.id || 'N/A'}</strong>
              </div>
              <div className="detail-item">
                <span>Purchase Date</span>
                <strong>{formatDate(device.purchaseDate)}</strong>
              </div>
              {device.license && (
                <>
                  <div className="detail-item">
                    <span>License Type</span>
                    <strong>
                      {device.license.tier === 'tier_1' && 'Basic License'}
                      {device.license.tier === 'tier_2' && 'Standard License'}
                      {device.license.tier === 'tier_3' && 'Premium License'}
                      {!['tier_1', 'tier_2', 'tier_3'].includes(device.license.tier) && device.license.tier}
                    </strong>
                  </div>
                  <div className="detail-item">
                    <span>Readings Left</span>
                    <strong>{device.license.clicksRemaining || 0} / {device.license.clicksTotal || 0}</strong>
                  </div>
                  <div className="detail-item">
                    <span>Expiration</span>
                    <strong>{formatDate(device.license.expirationDate)}</strong>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Licenses Tab Component
const LicensesTab = ({ userData, formatDate }) => {
  const { tiers = [] } = userData;
  
  if (tiers.length === 0) {
    return <div className="no-data-message">You don't have any licenses yet</div>;
  }
  
  const getTierName = (tier) => {
    switch (tier) {
      case 'tier_1': return 'Basic License';
      case 'tier_2': return 'Standard License';
      case 'tier_3': return 'Premium License';
      default: return tier;
    }
  };
  
  const calculateDaysLeft = (expirationDate) => {
    if (!expirationDate) return 'Unknown';
    
    try {
      const expiry = new Date(expirationDate);
      const today = new Date();
      const diffTime = expiry - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays < 0) return 'Expired';
      if (diffDays === 0) return 'Expires today';
      if (diffDays === 1) return '1 day left';
      return `${diffDays} days left`;
    } catch (e) {
      return 'Unknown';
    }
  };
  
  return (
    <div className="licenses-tab">
      <h2>Your Licenses</h2>
      <div className="licenses-grid">
        {tiers.map(license => (
          <div className="license-card" key={license.id}>
            <div className="license-header">
              <h3>{getTierName(license.tier)}</h3>
              {license.isFree && <span className="license-badge free">Free</span>}
            </div>
            
            <div className="license-details">
              <div className="detail-item">
                <span>Device</span>
                <strong>{license.deviceName || license.deviceId || 'Unknown Device'}</strong>
              </div>
              
              <div className="detail-item">
                <span>Readings Available</span>
                <strong>{license.clicksRemaining} / {license.clicksTotal}</strong>
              </div>
              
              <div className="detail-item">
                <span>Expiration</span>
                <strong>{formatDate(license.expirationDate)}</strong>
              </div>
              
              <div className="detail-item">
                <span>Status</span>
                <strong>{calculateDaysLeft(license.expirationDate)}</strong>
              </div>
            </div>
            
            <div className="usage-bar-container">
              <div className="usage-label">
                <span>Usage</span>
                <span>{Math.round((1 - license.clicksRemaining / license.clicksTotal) * 100)}%</span>
              </div>
              <div className="usage-bar">
                <div 
                  className="usage-fill" 
                  style={{ width: `${(license.clicksRemaining / license.clicksTotal) * 100}%` }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Purchases Tab Component
const PurchasesTab = ({ userData, formatDate }) => {
  const { orders = [] } = userData;
  
  if (orders.length === 0) {
    return <div className="no-data-message">You haven't made any purchases yet</div>;
  }
  
  // Sort orders by date (newest first)
  const sortedOrders = [...orders].sort((a, b) => {
    const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
    const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
    return dateB - dateA;
  });
  
  return (
    <div className="dashboard-page">
    <div className="purchases-tab">
      <h2>Purchase History</h2>
      <div className="orders-list">
        {sortedOrders.map(order => (
          <div className="order-card" key={order.id}>
            <div className="order-header">
              <div className="order-title">
                <h3>Order #{order.id.substring(0, 8)}</h3>
                <span className="order-date">{formatDate(order.createdAt)}</span>
              </div>
              <div className="order-amount">
                ${(order.totalAmount || 0).toFixed(2)}
              </div>
            </div>
            
            <div className="order-items">
              <h4>Items</h4>
              {order.products && Array.isArray(order.products) ? (
                <table className="items-table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.products.map((product, index) => (
                      <tr key={index}>
                        <td>{product.name || 'Product'}</td>
                        <td>{product.quantity || 1}</td>
                        <td>${(product.amount || 0).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="no-data-message">No items found for this order</p>
              )}
            </div>
            
            <div className="order-status">
              Status: <strong>{order.status || 'Completed'}</strong>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Dashboard;